@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































.custom-dialog {
  p {
    margin: 10px 0;
  }
}
::v-deep {
  .el-dialog__body {
    padding-top: 0px;
  }
}
