@import "~@/erp/styles/variables/variables.scss";



































































































































.remark {
  > .el-col {
    p {
      margin-bottom: 14px;
    }
    > span {
      font-size: 16px;
      color: #000;
    }
  }
}
