@import "~@/erp/styles/variables/variables.scss";























































































































































































.account-info {
  padding: 10px;
  border: 1px solid #eee;
}
