@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































































































































































































































































































































































































































.payment-detail-wrapper {
  .el-row .el-col {
    display: flex;
    align-items: center;
    .flex1 {
      flex: 1;
    }
    &.flex-start {
      align-items: flex-start;
    }
  }
  .list-wrapper {
    margin-top: 20px;
    & + .list-wrapper {
      margin-top: 40px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      .order-num {
        display: flex;
        align-items: center;
        > i {
          cursor: pointer;
        }
        .blue {
          width: 3px;
          height: 20px;
          margin: 0 6px;
          background-color: #0486fe;
        }
      }
    }
    .tip {
      background-color: rgba(254, 240, 240, 1);
      color: #f56c6c;
      border-radius: 5px;
      font-size: 14px;
      line-height: 24px;
      padding: 10px 20px;
      margin-top: 10px;
      font-weight: bold;
    }
    .el-table {
      // 高亮行
      ::v-deep {
        .highlight-row {
          background-color: #f6fbff;
        }
      }
      .el-table__cell {
        .tag {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          padding: 2px 4px;
          line-height: 1;
          border-radius: 2px;
          &.pay {
            color: #1890ff;
            background-color: #e8f4ff;
            border: 1px solid #d1e9ff;
          }
          &.refund {
            color: #ff4d4f;
            background-color: #ffeded;
            border: 1px solid #ffdbdc;
          }
        }
      }
    }
  }
  ::v-deep {
    // 发货凭证/已盖章合同
    .erp-upload-wrapper {
      display: flex;
      .upload_file {
        margin-top: -6px;
        .file_list {
          margin-top: 0;
          .file_line:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
    // 子表格
    .el-table__expanded-cell {
      padding: 5px;
      .el-table__header {
        tr {
          height: auto;
          .el-table__cell {
            padding: 0;
            height: 32px;
          }
        }
      }
    }
    .el-table__expand-column .el-icon {
      visibility: visible;
    }
  }
}
