@import "~@/erp/styles/variables/variables.scss";












































































































.table-custom {
  border-collapse: collapse;
  text-align: left;

  td {
    border: #ebeef5 1px solid;
    text-align: center;
  }

  td:nth-child(odd) {
    background-color: #f5f7fa;
  }
}
