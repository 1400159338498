@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































.custom-dialog {
  .el-form-item {
    margin-bottom: 18px;
  }

  p {
    margin: 0 0 10px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.red {
  color: red;
}

.supplierName-row {
  .supplierName-p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
}
