@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































































































































































































































































































































































































































































































































.content {
  .el-row {
    label {
      color: #666;
    }

    p {
      color: #000;
      font-size: 16px;
      margin-top: 5px;
    }
  }

  .row-line {
    border-bottom: 1px solid #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.link {
  color: #46a6ff;
  cursor: pointer;
  text-decoration: underline;
}

.green-bold {
  color: green;
  font-weight: bold;
}

.no-text {
  color: #999 !important;
}

::v-deep {
  .el-tooltip {
    span {
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
}

.money-sum {
  color: #666;
  text-align: right;
  padding: 5px;

  span {
    margin: 0 5px;
  }
}
