@import "~@/erp/styles/variables/variables.scss";



















































































































































































.detail-header {
  margin-bottom: 20px;
  > .el-col {
    p {
      margin-bottom: 14px;
    }
    > span {
      font-size: 16px;
      color: #000;
    }
    .green {
      color: green;
      font-weight: bold;
    }
  }
}

::v-deep {
  .el-dialog__header {
    padding-bottom: 0;
    > .el-dialog__title {
      font-size: 16px;
    }
  }
}
