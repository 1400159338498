@import "~@/erp/styles/variables/variables.scss";




























































































































table.custom-table {
  table-layout: fixed;
  word-break: break-all;
}
