@import "~@/erp/styles/variables/variables.scss";













































































































































































































































































































































.custom-dialog {
  p {
    margin: 10px 0;
    span {
      font-size: 16px;
      font-family: 'PingFang Bold';
    }
  }
  /deep/.el-table table th.star div:before {
    content: '*';
    color: red;
  }
  /* 带表单验证的table */
  .form-rule-table {
    /deep/ {
      .cell {
        overflow: initial;
        .el-form-item__content {
          padding: 5px 0;
          .el-form-item__error {
            top: 38px;
          }
        }
      }
    }
  }
}
