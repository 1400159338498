@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































.custom-dialog {
  p {
    margin: 15px 0;
  }
}
