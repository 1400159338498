@import "~@/erp/styles/variables/variables.scss";




















































































































































































































.info {
  margin-top: 5px;
  color: #999;
  font-size: 12px;
}
