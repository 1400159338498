@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}

.w240 {
  width: 240px;
}
