@import "~@/erp/styles/variables/variables.scss";














































































































.dialog-footer {
  display: flex;
  justify-content: center;
}
.erp-upload {
  margin-top: 0;
}
