@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .top-table {
    td {
      .cell {
        height: 50px;
      }
    }
  }

  .middle-table {
    .el-tooltip {
      height: 50px;
      line-height: 50px;
    }
  }

  .el-input,
  .el-select {
    height: 36px !important;
  }

  .money-sum {
    color: #666;
    text-align: right;
    padding: 5px;

    span {
      margin: 0 5px;
    }
  }
}
