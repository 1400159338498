@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































.content {
  .detail-header {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  .remark {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  /deep/ .el-divider--horizontal {
    margin: 20px 0;
  }
}
